<template>
  <div class="page populars">
    <div class="populars__header">
      <h2>Популярные услуги</h2>
      <div class="populars__toolbar">
        <esmp-input
          v-model="searchQuery"
          size="small"
          label="Поиск по популярным услугам"
          class="populars__search"
          clearable
          @input="debouncedSearchMethod"
        />
        <esmp-button
          size="small"
          @click="showAddPopularModal()"
        >
          Добавить
        </esmp-button>
      </div>
    </div>
    <div class="populars__table" v-if="filteredPopularServices.length">
      <div class="populars__item populars__item--header">
        <div class="populars__item-service-id">
          id
        </div>
        <div class="populars__item-service-name">
          Заголовок
        </div>
        <div class="populars__item-subscriptions" />
        <div class="populars__action" />
      </div>
      <div
        class="populars__item"
        v-for="item in filteredPopularServices"
        :key="item.id"
      >
        <div class="populars__item-service-id">
          {{ item.id }}
        </div>
        <div class="populars__item-service-name">
          {{ item.name }}
        </div>
        <div class="populars__item-subscriptions">
          <esmp-button
            @click="showAddPopularModal(item)"
            size="small"
          >
            Подписки
          </esmp-button>
        </div>
        <esmp-button
          class="populars__action"
          icon="trash"
          view="function"
          @click="removePopularService(item.id)"
        />
      </div>
    <!--     Будет реализовано позже, на беке временно удалили данный функционал-->
    <!--      <draggable-->
    <!--        :list="filteredPopularServices"-->
    <!--        group="columns"-->
    <!--        @change="updatePopularServices"-->
    <!--      >-->
    <!--        <div-->
    <!--          class="populars__item"-->
    <!--          v-for="item in filteredPopularServices"-->
    <!--          :key="item.id"-->
    <!--        >-->
    <!--          <div class="populars__item-service-id">-->
    <!--            {{ item.id }}-->
    <!--          </div>-->
    <!--          <div class="populars__item-service-name">-->
    <!--            {{ item.name }}-->
    <!--          </div>-->
    <!--          <esmp-button-->
    <!--            class="populars__action"-->
    <!--            icon="trash"-->
    <!--            view="function"-->
    <!--            @click="removePopularService(item.id)"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </draggable>-->
    </div>
    <div class="populars__empty" v-if="!filteredPopularServices.length">
      Список пуст
    </div>
    <esmp-pagination-adaptive
      class="populars__pagination"
      :page-count="pageCount"
      :current-page.sync="currentPage"
    />
    <add-popular-modal
      :showed="isShowedAddPopularModal"
      :subscription-list="otsSubscrList"
      :edited-popular-service="editedPopularService"
      @add-popular-service="addPopularService"
      @update-subscriptions="updateSubscriptions"
      @close-add-popular-modal="closeAddPopularModal"
    />
  </div>
</template>

<script>
// import draggable from 'vuedraggable';
import debounce from 'lodash/debounce';
import { MIN_QUERY_LENGTH, SEARCH_DELAY } from '@/constants/search';
import { mapActions } from 'vuex';
import AddPopularModal from '@/pages/admin/popular/AddPopularModal.vue';

export default {
  name: 'PopularServices',
  components: {
    AddPopularModal,
    // draggable,
  },
  data() {
    return {
      popularServices: [],
      searchResult: [],
      removeConfirmModalShowed: false,
      pageCount: 0,
      currentPage: 1,
      searchQuery: '',
      isShowedAddPopularModal: false,
      editedPopularService: null,
      otsSubscrList: [],
    };
  },
  watch: {
    searchQuery(val) {
      if (!val) this.searchResult = [];
    },
  },
  computed: {
    filteredPopularServices() {
      if (this.searchQuery?.length >= MIN_QUERY_LENGTH) {
        return this.searchResult;
      }
      return this.popularServices;
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    getPopularServices() {
      this.setLoading({ key: 'page', value: true });
      this.$API.popular.getPopularServices({
        page: this.currentPage - 1,
      }).then(({ data }) => {
        this.popularServices = data.content || [];
        this.pageCount = data.totalPages || 0;
      }).finally(() => {
        this.setLoading({ key: 'page', value: false });
      });
    },
    addPopularService(selectedServiceId, subscriptions) {
      this.setLoading({ key: 'page', value: true });
      this.$API.popular.addPopularService(selectedServiceId, subscriptions)
        .then(({ data }) => {
          this.popularServices.unshift(data);
          this.currentPage = 1;
          this.isShowedAddPopularModal = false;
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    updateSubscriptions(selectedServiceId, subscriptions) {
      this.setLoading({ key: 'page', value: true });
      this.$API.popular.updateSubscriptions(selectedServiceId, subscriptions)
        .then(() => {
          this.isShowedAddPopularModal = false;
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    closeAddPopularModal() {
      this.isShowedAddPopularModal = false;
      this.editedPopularService = null;
      this.otsSubscrList = [];
    },
    removePopularService(id) {
      this.setLoading({ key: 'page', value: true });
      this.$API.popular.removePopularService(id)
        .then(() => {
          this.popularServices = this.popularServices.filter((row) => row.id !== id);
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    searchServices() {
      if (this.searchQuery?.length >= MIN_QUERY_LENGTH) {
        this.setLoading({ key: 'page', value: true });
        this.$API.popular.searchPopularServices(this.searchQuery)
          .then(({ data }) => {
            this.searchResult = data.content || [];
          })
          .finally(() => {
            this.setLoading({ key: 'page', value: false });
          });
      }
    },
    showAddPopularModal(service) {
      if (service) {
        this.editedPopularService = service;
        this.getSubscription(service.id);
      } else {
        this.isShowedAddPopularModal = true;
      }
    },
    getSubscription(id) {
      this.setLoading({ key: 'page', value: true });
      this.$API.popular.getSubscriptions(id).then(({ data }) => {
        const otsSubscrIds = data.map((el) => el.id);
        const uniqOtsSubscrIds = [...new Set(otsSubscrIds)];
        this.otsSubscrList = uniqOtsSubscrIds.map((el) => data.find((i) => i.id === el));
        this.isShowedAddPopularModal = true;
      }).finally(() => {
        this.setLoading({ key: 'page', value: false });
      });
    },
    // Будет реализовано позже, на беке временно удалили данный функционал
    // updatePopularServices() {
    //   this.setLoading({ key: 'page', value: true });
    //   const list = this.filteredPopularServices.map((service, index) => {
    //     // eslint-disable-next-line no-param-reassign
    //     service.sortOrder = index;
    //     return service;
    //   });
    //   this.$API.popular.updatePopularServices(list)
    //     .then(({ data }) => {
    //       this.popularServices = data || [];
    //     })
    //     .finally(() => {
    //       this.setLoading({ key: 'page', value: false });
    //     });
    // },
  },
  created() {
    this.debouncedSearchMethod = debounce(this.searchServices, SEARCH_DELAY);
  },
  mounted() {
    this.getPopularServices();
  },
};
</script>

<style lang="scss">
.populars {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $base-gutter;
  }
  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
  }
  &__pagination {
    margin-top: $base-gutter;
    justify-content: center;
  }
  &__search {
    margin-right: $base-gutter;
    width: 400px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grayscale-10;
    padding: 10px;
    //cursor: move;
    &:nth-child(odd) {
      background-color: $color-white;
    }
    &--header {
      background-color: $color-grayscale-05 !important;
    }
  }
  &__item-service-name {
    width: 100%;
  }
  &__item-service-id {
    width: 100px;
    flex-shrink: 0;
  }
  &__action {
    width: 48px;
    flex-shrink: 0;
  }
}
</style>
